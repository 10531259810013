import Modal from "react-modal";
import { useNavigate } from 'react-router-dom'
import { useState } from "react";

const EmailModal = ({isOpen, toggleModal, isMobile}) => {

  const [email, setEmail] = useState('');
  const handleEmailChange = (e) => setEmail(e.target.value);
  const Airtable = require('airtable');
  const base = new Airtable({apiKey: 'keyWAIctby7B4bkXz'}).base('appbLkrDU50wZsEh9');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function onSubmitEmail() {
    if (validateEmail(email)) {
      base('Entry').create({
        "Email Address": email
      }, function(err, record) {
        if (err) {
          console.error(err);
          return;
        }
        console.log(record.getId());

        // close modal and navigate to the homepage when valid email and form submitted
        toggleModal();
        navigate('/');
      }).then(_ => {});
    } else {
      alert("Not a valid email")
    }
  }

  const desktopStyle = {
    width: '60%',
    margin: '0 auto',
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="Please enter your email"
      className="modal"
      overlayClassName="overlay"
      ariaHideApp={false}
      >
      <div style={ isMobile ? null : desktopStyle}>
        <h1 className="modal-header">One more thing, please enter your email address:</h1>
        <input
          className="email-input"
          placeholder="Email Address"
          value={email}
          onChange={handleEmailChange}
        ></input>
        <button className="button" onClick={onSubmitEmail}>Submit</button>
      </div>
    </Modal>
  )
}

export default EmailModal;
