import {Link} from "react-router-dom";
import logo from '../assets/jittor-logo.svg'

const Title = () => {
  const imgStyle = {
    height: 48,
    marginRight: 10
  }
  return (
    <Link to="/" style={{
      textDecoration: 'none',
      color: 'black',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <img src={logo} alt="triangle" style={imgStyle}/>
        <p className="app-title">JITTOR</p>
      </div>
      <hr
        style={{
          height: 5,
          backgroundColor: 'black',
          borderWidth: 0,
          borderRadius: 3
        }}/>
    </Link>
  )
}

export default Title;
