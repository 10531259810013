import './App.css';
import { BrowserRouter as Router, Routes, Route}
  from 'react-router-dom';

import Home from './pages/Home';
import Surgery from './pages/Surgery';

function App() {
  return (
    <div>
    <Router >
      <Routes>
        <Route exact path='/' element={<Home />} style={{
          backgroundColor: 'red'
        }}/>
        <Route path='/Surgery' element={<Surgery />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
