import {Link} from "react-router-dom";
import triangle from '../assets/triangle.svg'
import Title from "../components/Title";
import { useMediaQuery } from 'react-responsive'

// import current surgeries from the json file
import data from '../surgeries.json'

const Item = (props) => {

  const route = `/Surgery?q=${props.data}`

  return(
    <Link to={route} style={{
          textDecoration: 'none',
          display: 'flex',
          fontSize: 24,
          fontWeight: 'bold',
          color: 'black',
          margin: 15
        }}>
        {props.data.replace('-', ' ')}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
      }}>
        <img src={triangle} alt="triangle"/>
      </div>
    </Link>
  )
}

const Home = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });

  return (
    <div className="app-container"
     style={{
       display: 'flex',
       justifyContent: 'center',
     }}
    >

      <div style={isMobile ? null : {width: '60%'}}>
        <Title />

        <p
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            margin: 0,
            marginTop: 15,
          }}
        >Surgical Reviews</p>
        <p>
          Review the steps for a surgery before heading into the OR. Select a surgery below, review the notes, and then tap “heading into surgery”. Enjoy!
        </p>
        <hr
          style={{
            height: 5,
            backgroundColor: 'black',
            borderWidth: 0,
            borderRadius: 3
          }}/>

        {/* Render the list of surgeries*/}
        {Object.entries(data).map(([k, _]) => <Item key={k} data={k} />)}
      </div>
    </div>
  )
}

export default Home;
