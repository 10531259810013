import { useSearchParams } from "react-router-dom";
import Title from "../components/Title";
import React, { useState } from "react";
import EmailModal from "../components/EmailModal.js";
import {useMediaQuery} from "react-responsive";

// import current surgeries from the json file
import data from '../surgeries.json'


const Surgery = () => {

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });

  const [searchParams] = useSearchParams();
  const q = searchParams.get('q')
  const steps = data[q]

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const styleTransition = {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    transition:'background-color 0.2s',
  }

  const desktopStyle = {
    width: '60%',
    margin: '0px auto',
  }

  return (
    <div
      className="app-container"
      style={{...isOpen ? styleTransition: null, alignContent: 'center'}}
    >
      <div style={{...isMobile ? null : desktopStyle}}>

        <Title />

        <p style={{
          fontSize: 24,
          fontWeight: 'bold'
        }}>{q.replace('-', ' ')}</p>

        <ol style={{
          marginBottom: 20,
          marginTop: 20,
        }}>
          {steps.steps.map((d) => <li style={liStyle} key={d.body}>{d.body}</li>)}
        </ol>

        {/* appendix item*/}
        { steps.appendix !== null && <p style={{
          paddingBottom: 10,
          fontSize: 12
        }}>{steps.appendix}</p>}


        <button className="button" onClick={toggleModal}>Going in for surgery</button>

        {/* Email Modal Component*/}
        <EmailModal isOpen={isOpen} toggleModal={toggleModal} isMobile={isMobile}/>
      </div>
    </div>
  )
}

const liStyle = {
  fontSize: '15pt',
  marginBottom: 15,
}

export default Surgery;
